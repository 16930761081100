@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply border-border;
  }

  a {
    /* text-decoration: none; */
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  min-width: 320px;
  height: 100%;
  border-radius: 16px !important;
  padding-bottom: 10px !important;
}

button.mapboxgl-popup-close-button {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #1b6145;
  font-size: 1rem;
  text-indent: 5px;
  display: none;
}

.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_7351_97386)'%3E%3Cpath d='M14.998 8.99997C14.998 12.3137 12.3118 15 8.99805 15M14.998 8.99997C14.998 5.68626 12.3118 2.99997 8.99805 2.99997M14.998 8.99997H16.498M8.99805 15C5.68434 15 2.99805 12.3137 2.99805 8.99997M8.99805 15V16.5M2.99805 8.99997C2.99805 5.68626 5.68434 2.99997 8.99805 2.99997M2.99805 8.99997H1.49805M8.99805 2.99997V1.49997M11.248 8.99997C11.248 10.2426 10.2407 11.25 8.99805 11.25C7.75541 11.25 6.74805 10.2426 6.74805 8.99997C6.74805 7.75733 7.75541 6.74997 8.99805 6.74997C10.2407 6.74997 11.248 7.75733 11.248 8.99997Z' stroke='%23061F15' stroke-width='1.125' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_7351_97386'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") !important;
}

.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.89356 7.848V0.872H5.09356V7.848H3.89356ZM0.837563 4.92V3.784H8.14956V4.92H0.837563Z' fill='black'/%3E%3C/svg%3E%0A") !important;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='2' viewBox='0 0 7 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.339875 1.856V0.72H6.67588V1.856H0.339875Z' fill='black'/%3E%3C/svg%3E%0A") !important;
}

.distance-to-point-popup .mapboxgl-popup-content {
  @apply !pointer-events-none !rounded-2xl !rounded-tr-none bg-white !p-0 shadow-lg;
}

.mapboxgl-popup-content {
  left: 20px;
  width: 100% !important;
}

.mapboxgl-popup-marker {
  transform: none !important;
  top: calc(100% - 200px) !important;
  z-index: 1;
}

@media (max-width: 768px) {
  .mapboxgl-popup-marker {
    top: calc(100% - 250px) !important;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .mapboxgl-popup-content {
    z-index: 2;
  }
}
